// src/pages/AlbumComponent.js
import React, {useEffect, useState} from 'react';
import './AlbumComponent.css'
import 'spotlight.js/dist/css/spotlight.min.css';
import Spotlight  from "spotlight.js"; // khong duoc xoa

function AlbumComponent() {

    const [imageLP, setImageLP] = useState([]);

    useEffect(() => {
        async function fetchImages() {
            try {
                // const response = await fetch('http://localhost/api/imageLP', {
                const response = await fetch('https://admin.dangphucsport.vn/api/imageLP', {
                    headers: {
                        'accept': 'application/json, text/plain, */*',
                    }
                });
                const data = await response.json();
                setImageLP(data.data);
            } catch (error) {
                console.error('Error fetching imageLP:', error);
            }
        }

        fetchImages();
    }, []);
    useEffect(() => {

        const initializeSpotlight = () => {
            if (window.Spotlight) {
                window.Spotlight.init();
            }
        };

        // Đảm bảo Spotlight.js được tải đầy đủ
        if (document.readyState === 'complete') {
            initializeSpotlight();
        } else {
            window.addEventListener('load', initializeSpotlight);
        }
    }, []);
    if (!imageLP?.length) {
        return <div className="loader-container">
            <div className="loader"></div>
        </div>;
    }
    return (
        <div className="block-album ">
            <div className="album-title">
                <div className="album-title__title">Album ảnh</div>
                <a href="/hinh-anh" className="album-title__btn btn-dp">
                    Xem tất cả
                    <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8.04751 4.60205L15.9453 12.4999L8.04751 20.3977"
                            stroke="#5C5C5C"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </a>
            </div>
            <div className="desktop">
                <div className="album-content spotlight-group">
                    <div className="flex gap-4">
                        <a href={'https://admin.dangphucsport.vn/' + imageLP[0].image_path} className="spotlight item-3-col"
                           style={{height: '364px'}}>
                            <img alt="hinh anh" src={'https://admin.dangphucsport.vn/' + imageLP[0].image_path}/>
                        </a>
                        <a href={'https://admin.dangphucsport.vn/' + imageLP[1].image_path} className="spotlight item-3-col"
                           style={{height: '364px'}}>
                            <img alt="hinh anh" src={'https://admin.dangphucsport.vn/' + imageLP[1].image_path}/>
                        </a>
                        <a href={'https://admin.dangphucsport.vn/' + imageLP[2].image_path} className="spotlight item-3-col"
                           style={{height: '364px'}}>
                            <img alt="hinh anh" src={'https://admin.dangphucsport.vn/' + imageLP[2].image_path}/>
                        </a>
                    </div>
                    <div className="flex gap-4">
                        <a href={'https://admin.dangphucsport.vn/' + imageLP[3].image_path} className="spotlight item-4-col"
                           style={{height: '528px'}}>
                            <img alt="hinh anh" src={'https://admin.dangphucsport.vn/' + imageLP[3].image_path}/>
                        </a>
                        <a href={'https://admin.dangphucsport.vn/' + imageLP[4].image_path} className="spotlight item-5-col"
                           style={{height: '528px'}}>
                            <img alt="hinh anh" src={'https://admin.dangphucsport.vn/' + imageLP[4].image_path}/>
                        </a>
                    </div>
                </div>
            </div>
            <div className="mobile">
                <div className="album-content spotlight-group">
                    <a href={'https://admin.dangphucsport.vn/' + imageLP[0].image_path} className="spotlight item-full"
                       style={{height: '192px'}}>
                        <img alt="hinh anh" src={'https://admin.dangphucsport.vn/' + imageLP[0].image_path}/>
                    </a>
                    <div className="flex gap-4">
                        <a href={'https://admin.dangphucsport.vn/' + imageLP[1].image_path} className="spotlight item-1-col"
                           style={{height: '192px'}}>
                            <img alt="hinh anh" src={'https://admin.dangphucsport.vn/' + imageLP[1].image_path}/>
                        </a>
                        <a href={'https://admin.dangphucsport.vn/' + imageLP[2].image_path} className="spotlight item-1-col"
                           style={{height: '192px'}}>
                            <img alt="hinh anh" src={'https://admin.dangphucsport.vn/' + imageLP[2].image_path}/>
                        </a>
                    </div>
                    <div className="flex gap-4">
                        <a href={'https://admin.dangphucsport.vn/' + imageLP[3].image_path} className="spotlight item-1-col"
                           style={{height: '192px'}}>
                            <img alt="hinh anh" src={'https://admin.dangphucsport.vn/' + imageLP[3].image_path}/>
                        </a>
                        <a href={'https://admin.dangphucsport.vn/' + imageLP[4].image_path} className="spotlight item w-100"
                           style={{height: '192px'}}>
                            <img alt="hinh anh" src={'https://admin.dangphucsport.vn/' + imageLP[4].image_path}/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AlbumComponent;