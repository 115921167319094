// src/components/Header.js
import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {toggleContactPopup} from '../redux/actions/contactPopupActions';
import logo from '../assets/images/DP_logo.png';
import trongDong from '../assets/images/trong-dong-header.png';

function Header() {
    const dispatch = useDispatch();
    const isOpenContactPopup = useSelector(
        (state) => state.contactPopup.isOpenContactPopup
    );

    const handleToggle = () => {
        dispatch(toggleContactPopup());
    };
    const currentPath = window.location.pathname;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    return (
        <header>
            <div className='dp-container dp-header '>
                <a href="/" className="flex gap-1">
                    <img src={logo} alt="Logo" style={{height: '65px', padding: '8px'}}/>
                </a>

                <div className="trong-dong">
                    <img src={trongDong} alt="Logo"/>
                </div>
                <div className="menu desktop">
                    <ul>
                        <li><a href="/" className={currentPath === '/' ? 'active' : ''}>Trang chủ</a></li>
                        <li><a href="/gioi-thieu" className={currentPath === '/gioi-thieu' ? 'active' : ''}>Giới thiệu</a></li>
                        <li><a href="/khoa-hoc" className={currentPath === '/khoa-hoc' ? 'active' : ''}>Khoá học</a></li>
                        <li><a href="/tin-tuc" className={currentPath === '/tin-tuc' ? 'active' : ''}>Tin tức</a></li><li>
                            <div className={`btn`} onClick={handleToggle}>Tư vấn ngay
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="mobile btn-dropdown" onClick={toggleMenu}>
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M36 10H14C13.2044 10 12.4413 10.3161 11.8787 10.8787C11.3161 11.4413 11 12.2044 11 13C11 13.7956 11.3161 14.5587 11.8787 15.1213C12.4413 15.6839 13.2044 16 14 16H36C36.7956 16 37.5587 15.6839 38.1213 15.1213C38.6839 14.5587 39 13.7956 39 13C39 12.2044 38.6839 11.4413 38.1213 10.8787C37.5587 10.3161 36.7956 10 36 10ZM36 14H14C13.7348 14 13.4804 13.8946 13.2929 13.7071C13.1054 13.5196 13 13.2652 13 13C13 12.7348 13.1054 12.4804 13.2929 12.2929C13.4804 12.1054 13.7348 12 14 12H36C36.2652 12 36.5196 12.1054 36.7071 12.2929C36.8946 12.4804 37 12.7348 37 13C37 13.2652 36.8946 13.5196 36.7071 13.7071C36.5196 13.8946 36.2652 14 36 14Z"
                            fill="white"/>
                        <path
                            d="M36 22H14C13.2044 22 12.4413 22.3161 11.8787 22.8787C11.3161 23.4413 11 24.2044 11 25C11 25.7956 11.3161 26.5587 11.8787 27.1213C12.4413 27.6839 13.2044 28 14 28H36C36.7956 28 37.5587 27.6839 38.1213 27.1213C38.6839 26.5587 39 25.7956 39 25C39 24.2044 38.6839 23.4413 38.1213 22.8787C37.5587 22.3161 36.7956 22 36 22ZM36 26H14C13.7348 26 13.4804 25.8946 13.2929 25.7071C13.1054 25.5196 13 25.2652 13 25C13 24.7348 13.1054 24.4804 13.2929 24.2929C13.4804 24.1054 13.7348 24 14 24H36C36.2652 24 36.5196 24.1054 36.7071 24.2929C36.8946 24.4804 37 24.7348 37 25C37 25.2652 36.8946 25.5196 36.7071 25.7071C36.5196 25.8946 36.2652 26 36 26Z"
                            fill="white"/>
                        <path
                            d="M36 34H14C13.2044 34 12.4413 34.3161 11.8787 34.8787C11.3161 35.4413 11 36.2044 11 37C11 37.7956 11.3161 38.5587 11.8787 39.1213C12.4413 39.6839 13.2044 40 14 40H36C36.7956 40 37.5587 39.6839 38.1213 39.1213C38.6839 38.5587 39 37.7956 39 37C39 36.2044 38.6839 35.4413 38.1213 34.8787C37.5587 34.3161 36.7956 34 36 34ZM36 38H14C13.7348 38 13.4804 37.8946 13.2929 37.7071C13.1054 37.5196 13 37.2652 13 37C13 36.7348 13.1054 36.4804 13.2929 36.2929C13.4804 36.1054 13.7348 36 14 36H36C36.2652 36 36.5196 36.1054 36.7071 36.2929C36.8946 36.4804 37 36.7348 37 37C37 37.2652 36.8946 37.5196 36.7071 37.7071C36.5196 37.8946 36.2652 38 36 38Z"
                            fill="white"/>
                    </svg>
                    <div className={'dropdown-menu menu' + (isMenuOpen ? ' show' : '')}>
                        <ul>
                            <li><a href="/" className={currentPath === '/' ? 'active' : ''}>Trang chủ</a></li>
                            <li><a href="/gioi-thieu" className={currentPath === '/gioi-thieu' ? 'active' : ''}>Giới thiệu</a>
                            </li>
                            <li><a href="/khoa-hoc" className={currentPath === '/khoa-hoc' ? 'active' : ''}>Khoá học</a>
                            </li>
                            <li><a href="/tin-tuc" className={currentPath === '/tin-tuc' ? 'active' : ''}>Tin tức</a></li>
                            <li>
                                <div className={`btn`} onClick={handleToggle}>Tư vấn ngay
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="modal" className={`modal-overlay ${isOpenContactPopup ? 'active' : ''}`} onClick={handleToggle}>
                <div className="modal">
                    <span className="close-button" onClick={handleToggle}>&times;</span>
                    <h3>LIÊN HỆ TƯ VẤN</h3>
                    <p>
                        Liên hệ ngay để được tư vấn chi tiết về các khóa học lập trình! Đội ngũ của chúng tôi sẽ hỗ trợ
                        bạn qua Facebook hoặc Zalo, giải đáp mọi thắc mắc và giúp bạn chọn khóa học phù hợp nhất với nhu
                        cầu học tập của mình.
                    </p>
                    <div className="modal-buttons">
                        <a target="_blank" rel="noreferrer" href="https://m.me/dangphucsportfc">FACEBOOK</a>
                        <a target="_blank" rel="noreferrer" href="https://zalo.me/0339775337">ZALO</a>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;