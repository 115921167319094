// src/pages/Home.js
import React, { useEffect, useState } from "react";
import "./Home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Pagination } from "swiper/modules"; // Import Pagination module
import "swiper/css"; // Import Swiper styles
import "swiper/css/pagination";
import NewsComponent from "../../components/NewsComponent/NewsComponent";
import CourseComponent from "../../components/CourseComponent/CourseComponent";
import VideoComponent from "../../components/VideoComponent/VideoComponent";
import AlbumComponent from "../../components/AlbumComponent/AlbumComponent";
import JerseyComponent from "../../components/JerseyComponent/JerseyComponent";
// import trongDong from "../../assets/images/trong-dong-header.png";
import trongDongBg from "../../assets/images/bg-trong-dong.png";
import DOMPurify from "dompurify";
import Football from "../../assets/images/football.png";
 
function decodeHtml(html) {
	const textArea = document.createElement("textarea");
	textArea.innerHTML = html;
	return textArea.value;
}
function Home() {
	const [images, setImages] = useState([]);
	const [textSliders, setTextSliders] = useState([]);

	useEffect(() => {
		async function fetchImages() {
			try {
				const response = await fetch(
					"https://admin.dangphucsport.vn/api/images",
					{
						headers: {
							accept: "application/json, text/plain, */*",
						},
					}
				);
				const data = await response.json();
				setImages(data.data);
			} catch (error) {
				console.error("Error fetching images:", error);
			}
		}
		async function fetchTextSliders() {
			try {
				const response = await fetch(
					"https://admin.dangphucsport.vn/api/textsliders",
					{
						headers: {
							accept: "application/json, text/plain, */*",
						},
					}
				);
				const result = await response.json();

				if (result && Array.isArray(result.data)) {
					const decodedSliders = result.data.map((slider) => ({
						...slider,
						title: decodeHtml(slider.title),
						textbutton: decodeHtml(slider.textbutton),
					}));
					setTextSliders(decodedSliders);
				} else {
					console.error("Invalid data format from API:", result);
					setTextSliders([]);
				}
			} catch (error) {
				console.error("Error fetching text sliders:", error);
				setTextSliders([]);
			}
		}

		fetchImages();
		fetchTextSliders();
	}, []);
	return (
		<div className="home-container">
			<div className="bg-trong-dong-color">
				<div className="bg-trong-dong">
					<div className="dp-container">
						<Swiper
							className="swiper-home"
							modules={[Pagination]}
							pagination={{
								clickable: true,
							}}
						>
							{textSliders.length > 0 ? (
								textSliders.slice(0, 5).map((slider, index) => (
									<SwiperSlide key={`slider-${index}`}>
										<div className="item-swiper">
											<div
												className="item-swiper__title"
												dangerouslySetInnerHTML={{
													__html: DOMPurify.sanitize(
													),
												}}
											></div>
											<div className="item-swiper__cta">
												<a
													href={slider.path}
													className="btn-dp"
												>
													<span
														dangerouslySetInnerHTML={{
															__html: DOMPurify.sanitize(
															),
														}}
													></span>
													<svg
														width="24"
														height="25"
														viewBox="0 0 24 25"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M8.04751 4.60205L15.9453 12.4999L8.04751 20.3977"
															stroke="#5C5C5C"
															strokeWidth="1.5"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</a>
											</div>
										</div>
									</SwiperSlide>
								))
							) : (
								<p></p>
							)}
						</Swiper>
						<div className="info-col">
							<div className="info-col__item">
								<div className="item-num">1000+</div>
								<div className="item-text">Học viên</div>
							</div>
							<div className="info-col__item">
								<div className="item-num">10</div>
								<div className="item-text">Cơ sở</div>
							</div>
							<div className="info-col__item">
								<div className="item-num">100+</div>
								<div className="item-text">Giáo viên</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="dp-container dp-container-video">
				<div className="block-vid video-guidelines">
					{/* Text Section */}
					<div className="text-section">
						<h3>Festival bóng đá mùa Xuân Đăng Phúc Cup 2025</h3>
						<p>
							Cám ơn Đài truyền hình Việt Nam (VTV) đã đến và thực
							hiện phóng sự về Festival bóng đá mùa Xuân Đăng Phúc
							Cup 2025.
						</p>
					</div>					
					<div style={{ flex: 2, width: "100%" }}>
						<iframe
							src="https://www.facebook.com/plugins/video.php?height=313&href=https%3A%2F%2Fwww.facebook.com%2Fdangphucsportfc%2Fvideos%2F1236652900737142%2F&show_text=false&width=560&t=0"
							style={{
								border: "none",
								overflow: "hidden",
								width: "100%",
								aspectRatio: "16 / 9",
								borderRadius: "15px",
							}}
							allowFullScreen
							allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
							loading="lazy"
						/>
					</div>
				</div>
			</div>
			<div className="dp-container">
				<AlbumComponent />
			</div>
			{/* <div className="dp-container" style={{ maxWidth: "1300px" }}>
				<div className="block-img">
					{images.length > 0 && (
						<img
							src={`https://admin.dangphucsport.vn/${
								images.find((image) => image.id === 24)
									?.image_path
							}`}
							alt="Selected"
						/>
					)}
				</div>
			</div>  */}

			<div className="dp-container">
				<CourseComponent />
				<NewsComponent />
			</div>
			<div className="grid-container dp-container">
				<div className="grid-item">1</div>
				<div className="grid-item">1</div>
				<div className="grid-item">
					<div className="flex-container display-mobile">
							<div className="title"></div>
							<div className="description">
								{/* Nơi ươm mầm tình yêu bóng đá và thể thao. */}
							</div>
						<div className="football">
							<img src={Football} className="football-icon" />
						</div>
					</div>
				</div>
				<div className="grid-item">
					<div className="flex-container display-desktop">
						<div className="title"></div>
						<div className="description">
							{/* Nơi ươm mầm tình yêu bóng đá và thể thao. */}
						</div>

						{/* <div className="football">
							<img src={Football} className="football-icon" />
						</div> */}
					</div>
				</div>
				<div className="grid-item"></div>
				<div className="grid-item">
					<div className="flex-container">
						<div className="title text-black"></div>
						<div className="description text-black">
							{/* Nơi Cùng nhau rèn luyện, tự tin tỏa sáng trên
							sân cỏ. */}
						</div>
						{/* <div className="football">
							<img
								src={personalCollection}
								className="football-icon"
							/>
						</div> */}
					</div>
				</div>
				<div className="grid-item">
					<div className="flex-container display-mobile">
						<div className="title text-black"></div>
						<div className="description text-black">
							{/* Nơi thỏa sức tranh tài và phát triển đam mê. */}
						</div>
						{/* <div className="football">
							<img src={medalOne} className="football-icon" />
						</div> */}
					</div>
				</div>
				<div className="grid-item ">
					<div className="flex-container display-desktop">
							<div className="title text-black"></div>
							<div className="description text-black">
								{/* Nơi thỏa sức tranh tài và phát triển đam mê. */}
							</div>
						{/* <div className="football">
							<img src={medalOne} className="football-icon" />
						</div> */}
					</div>
				</div>
				<div className="grid-item display-desktop"></div>
			</div>
			<div className="dp-container">
				<VideoComponent />
			</div>
			<div className="dp-container">
				<JerseyComponent />
			</div>
			<div className="bg-trong-dong-big">
				<img src={trongDongBg} alt="Logo" />
			</div>
		</div>
	);
}

export default Home;
