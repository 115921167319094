// src/pages/SingleNew.js
import React, {useEffect, useState} from 'react';
import "./SingleNew.css"
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";
import {toggleContactPopup} from "../../redux/actions/contactPopupActions";

// Khai báo propTypes
SingleNew.propTypes = {
    title: PropTypes.string,
    pathname: PropTypes.string,
};

// Khai báo defaultProps
SingleNew.defaultProps = {
    title: "",
    pathname: "",
};

function SingleNew({title,pathname}) {
    const dispatch = useDispatch();
    const handleToggle = () => {
        dispatch(toggleContactPopup());
    };

    const [courses, setCourses] = useState([]);

    useEffect(() => {
        async function fetchCourse() {
            try {
                // const response = await fetch(`http://localhost/api/${pathname}`, {
                const response = await fetch(`https://admin.dangphucsport.vn/api/${pathname}`, {
                    headers: {
                        'accept': 'application/json, text/plain, */*',
                    }
                });
                const data = await response.json();
                setCourses(data.data);
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        }

        fetchCourse();
    }, []);
    if (!courses?.length) {
        return <div className="loader-container">
            <div className="loader"></div>
        </div>;
    }
    return (
        <div className="single-news-content-container">
            {title && (<div className="single-news-content__title">{title}</div>)}
            {courses.map((item, index) => (
            <div className="news-content__main">
                <img src={'https://admin.dangphucsport.vn/' + item.image_path} alt={item.title} className="main-img" />
                <div className="main-content flex flex-column">
                    <a href={item?.url ? `${pathname}/${item.url}` : '#'}  className="content-title">{item.title}</a>
                    <div className="divider-line mt-2"></div>
                    <div className="content-text">
                        <div dangerouslySetInnerHTML={{__html: item.content}}>

                        </div>
                    </div>
                    <div onClick={handleToggle} className="content-more-info">
                    <div className="btn-dp">
                            Đăng ký tư vấn
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.04751 4.60205L15.9453 12.4999L8.04751 20.3977" stroke="#5C5C5C"
                                      strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            ))}

        </div>
    );
}

export default SingleNew;