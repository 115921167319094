// src/App.js
import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Consulting from './pages/Consuting/Consulting';
import News from './pages/News/News';
import Courses from './pages/Course/Course';
import { Provider } from 'react-redux';
import store from './redux/store';
import SingleNewPage from "./pages/SingleNews/SingleNewPage";
import SingleProgram from "./pages/SingleProgram/SingleProgram";
import SingleSpecializedClass from "./pages/SingleSpecializedClass/SingleSpecializedClass";
import AllImagesPage from './pages/AllImagesPage/AllImagesPage';
function App() {
    return (
        <Provider store={store}>
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/khoa-hoc" element={<Courses/>}/>
                    <Route path="/tin-tuc" element={<News/>}/>
                    <Route path="/gioi-thieu" element={<About/>}/>
                    <Route path="/tu-van" element={<Consulting/>}/>
                    <Route path="/:newId" element={<SingleNewPage />} />
                    <Route path="/sClass/:classId" element={<SingleSpecializedClass />} />
                    <Route path="/program/:programId" element={<SingleProgram />} />
                    <Route path="/program/:programId" element={<SingleProgram />} />
                    <Route path="/hinh-anh" element={<AllImagesPage />} />
                </Routes>
            </Layout>
        </Router>
        </Provider>
    );
}

export default App;