// src/components/Layout.js
import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Messenger from "../assets/images/mess.png";
import Phone from "../assets/images/phone.png";
import Zalo from "../assets/images/zalo.png";

const Layout = ({ children }) => {
	return (
		<div className="App">
			<Header />
			<main>{children}</main>
			<div className="custom-grid">
				<a href="tel:+0339775337" className="icon-wrapper">
					<img src={Phone} alt="Phone" style={{ width: "40px", height: "40px" }}/>
				</a>

				<a
					href={"https://zalo.me/0339775337"}
					target={"_blank"}
					rel={"noopener noreferrer"}
					className="icon-wrapper"
				>
					<img src={Zalo} alt="Zalo" style={{ width: "40px", height: "40px" }}/>
				</a>
				<a
					href="https://www.messenger.com/t/141274663114700/?messaging_source=source%3Apages%3Amessage_shortlink&source_id=1441792&recurring_notification=0"
					target={"_blank"}
					rel={"noopener noreferrer"}
					className="icon-wrapper"
				>
					<img
						
						src={Messenger}
						alt="Messenger"
						style={{ width: "40px", height: "40px" }}
					/>
				</a>
			</div>
			<Footer />
		</div>
	);
};

export default Layout;
