// src/components/Footer.js
import React from 'react';
import logo from '../assets/images/dp_logo_big.png';

function Footer() {
    return (
        <footer>
            <div className="dp-container">
                <div className="footer-container">
                    <div className="footer-title">
                        TRUNG TÂM BÓNG ĐÁ <span className="yellow">ĐĂNG PHÚC </span>
                    </div>
                    <div className="footer-content">
                        <img className="logo" src={logo} alt="Logo"/>
                        <div className="list-address flex">
                            <div className="address-item">
                                <div className="address-item__title">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6.5693 21.8379C4.15645 22.5618 2.66406 23.5618 2.66406 24.6663C2.66406 26.8754 8.6336 28.6663 15.9974 28.6663C23.3612 28.6663 29.3307 26.8754 29.3307 24.6663C29.3307 23.5618 27.8383 22.5618 25.4255 21.8379"
                                            stroke="#FED780" strokeWidth="3" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path
                                            d="M16.0026 23.3337C16.0026 23.3337 24.6693 17.6697 24.6693 11.1215C24.6693 6.45222 20.7891 2.66699 16.0026 2.66699C11.2161 2.66699 7.33594 6.45222 7.33594 11.1215C7.33594 17.6697 16.0026 23.3337 16.0026 23.3337Z"
                                            stroke="#FED780" strokeWidth="3" strokeLinejoin="round"/>
                                        <path
                                            d="M15.9974 14.6667C17.8383 14.6667 19.3307 13.1743 19.3307 11.3333C19.3307 9.4924 17.8383 8 15.9974 8C14.1565 8 12.6641 9.4924 12.6641 11.3333C12.6641 13.1743 14.1565 14.6667 15.9974 14.6667Z"
                                            stroke="#FED780" strokeWidth="3" strokeLinejoin="round"/>
                                    </svg>
                                    CS 1: Quận 8
                                </div>
                                <div className="address-item__content">
                                    Sân bóng đá KFF, Số 1016 đường <br/>Tạ Quang Bửu, Phường 6, Quận 8.
                                    <br/>Tel: 0339.775.337 (Thầy Phúc); <br/>Tel: 0388.121.623 (Cô Tuyền)
                                </div>
                            </div>
                            <div className="address-item">
                                <div className="address-item__title">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6.5693 21.8379C4.15645 22.5618 2.66406 23.5618 2.66406 24.6663C2.66406 26.8754 8.6336 28.6663 15.9974 28.6663C23.3612 28.6663 29.3307 26.8754 29.3307 24.6663C29.3307 23.5618 27.8383 22.5618 25.4255 21.8379"
                                            stroke="#FED780" strokeWidth="3" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path
                                            d="M16.0026 23.3337C16.0026 23.3337 24.6693 17.6697 24.6693 11.1215C24.6693 6.45222 20.7891 2.66699 16.0026 2.66699C11.2161 2.66699 7.33594 6.45222 7.33594 11.1215C7.33594 17.6697 16.0026 23.3337 16.0026 23.3337Z"
                                            stroke="#FED780" strokeWidth="3" strokeLinejoin="round"/>
                                        <path
                                            d="M15.9974 14.6667C17.8383 14.6667 19.3307 13.1743 19.3307 11.3333C19.3307 9.4924 17.8383 8 15.9974 8C14.1565 8 12.6641 9.4924 12.6641 11.3333C12.6641 13.1743 14.1565 14.6667 15.9974 14.6667Z"
                                            stroke="#FED780" strokeWidth="3" strokeLinejoin="round"/>
                                    </svg>
                                    CS 2: Nhà Bè
                                </div>
                                <div className="address-item__content">Sân Bóng đá Khu Phố 6, <br/>Số 3 Huỳnh Thị Đồng, Thị Trấn Nhà Bè.
                                    <br/>Tel: 0339.775.337 (Thầy Phúc); <br/>Tel: 0388.121.623 (Cô Tuyền)
                                </div>
                            </div>
                            <div className="address-item">
                                <div className="address-item__title">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6.5693 21.8379C4.15645 22.5618 2.66406 23.5618 2.66406 24.6663C2.66406 26.8754 8.6336 28.6663 15.9974 28.6663C23.3612 28.6663 29.3307 26.8754 29.3307 24.6663C29.3307 23.5618 27.8383 22.5618 25.4255 21.8379"
                                            stroke="#FED780" strokeWidth="3" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path
                                            d="M16.0026 23.3337C16.0026 23.3337 24.6693 17.6697 24.6693 11.1215C24.6693 6.45222 20.7891 2.66699 16.0026 2.66699C11.2161 2.66699 7.33594 6.45222 7.33594 11.1215C7.33594 17.6697 16.0026 23.3337 16.0026 23.3337Z"
                                            stroke="#FED780" strokeWidth="3" strokeLinejoin="round"/>
                                        <path
                                            d="M15.9974 14.6667C17.8383 14.6667 19.3307 13.1743 19.3307 11.3333C19.3307 9.4924 17.8383 8 15.9974 8C14.1565 8 12.6641 9.4924 12.6641 11.3333C12.6641 13.1743 14.1565 14.6667 15.9974 14.6667Z"
                                            stroke="#FED780" strokeWidth="3" strokeLinejoin="round"/>
                                    </svg>
                                    CS 3: Tân Phú
                                </div>
                                <div className="address-item__content">Sân Văn Cao, số 73 Văn Cao, <br/>Phường Phú Thọ Hòa, Quận Tân Phú.
                                    <br/>Tel: 0339.775.337 (Thầy Phúc); <br/>Tel: 0388.121.623 (Cô Tuyền)
                                </div>
                            </div>
                            <div className="address-item">
                                <div className="address-item__title">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6.5693 21.8379C4.15645 22.5618 2.66406 23.5618 2.66406 24.6663C2.66406 26.8754 8.6336 28.6663 15.9974 28.6663C23.3612 28.6663 29.3307 26.8754 29.3307 24.6663C29.3307 23.5618 27.8383 22.5618 25.4255 21.8379"
                                            stroke="#FED780" strokeWidth="3" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path
                                            d="M16.0026 23.3337C16.0026 23.3337 24.6693 17.6697 24.6693 11.1215C24.6693 6.45222 20.7891 2.66699 16.0026 2.66699C11.2161 2.66699 7.33594 6.45222 7.33594 11.1215C7.33594 17.6697 16.0026 23.3337 16.0026 23.3337Z"
                                            stroke="#FED780" strokeWidth="3" strokeLinejoin="round"/>
                                        <path
                                            d="M15.9974 14.6667C17.8383 14.6667 19.3307 13.1743 19.3307 11.3333C19.3307 9.4924 17.8383 8 15.9974 8C14.1565 8 12.6641 9.4924 12.6641 11.3333C12.6641 13.1743 14.1565 14.6667 15.9974 14.6667Z"
                                            stroke="#FED780" strokeWidth="3" strokeLinejoin="round"/>
                                    </svg>
                                    CS 4: Quận 7
                                </div>
                                <div className="address-item__content">Sân Mansion Sport Club, Số 30 Đào Trí, <br/>Phường Phú Mỹ, Quận 7.
                                    <br/>Tel: 0339.775.337 (Thầy Phúc); <br/>Tel: 0388.121.623 (Cô Tuyền)
                                </div>
                            </div>
                            <div className="address-item">
                                <div className="address-item__title">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6.5693 21.8379C4.15645 22.5618 2.66406 23.5618 2.66406 24.6663C2.66406 26.8754 8.6336 28.6663 15.9974 28.6663C23.3612 28.6663 29.3307 26.8754 29.3307 24.6663C29.3307 23.5618 27.8383 22.5618 25.4255 21.8379"
                                            stroke="#FED780" strokeWidth="3" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path
                                            d="M16.0026 23.3337C16.0026 23.3337 24.6693 17.6697 24.6693 11.1215C24.6693 6.45222 20.7891 2.66699 16.0026 2.66699C11.2161 2.66699 7.33594 6.45222 7.33594 11.1215C7.33594 17.6697 16.0026 23.3337 16.0026 23.3337Z"
                                            stroke="#FED780" strokeWidth="3" strokeLinejoin="round"/>
                                        <path
                                            d="M15.9974 14.6667C17.8383 14.6667 19.3307 13.1743 19.3307 11.3333C19.3307 9.4924 17.8383 8 15.9974 8C14.1565 8 12.6641 9.4924 12.6641 11.3333C12.6641 13.1743 14.1565 14.6667 15.9974 14.6667Z"
                                            stroke="#FED780" strokeWidth="3" strokeLinejoin="round"/>
                                    </svg>
                                    CS 5: Tân Bình
                                </div>
                                <div className="address-item__content">Sân bóng Vạn Xuân, 18D, Cộng Hoà, <br/>Phường 4, Quận Tân Bình.
                                    <br/>Tel: 0339.775.337 (Thầy Phúc); <br/>Tel: 0388.121.623 (Cô Tuyền)
                                </div>
                            </div>
                            <div className="address-item">
                                <div className="address-item__title">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6.5693 21.8379C4.15645 22.5618 2.66406 23.5618 2.66406 24.6663C2.66406 26.8754 8.6336 28.6663 15.9974 28.6663C23.3612 28.6663 29.3307 26.8754 29.3307 24.6663C29.3307 23.5618 27.8383 22.5618 25.4255 21.8379"
                                            stroke="#FED780" strokeWidth="3" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path
                                            d="M16.0026 23.3337C16.0026 23.3337 24.6693 17.6697 24.6693 11.1215C24.6693 6.45222 20.7891 2.66699 16.0026 2.66699C11.2161 2.66699 7.33594 6.45222 7.33594 11.1215C7.33594 17.6697 16.0026 23.3337 16.0026 23.3337Z"
                                            stroke="#FED780" strokeWidth="3" strokeLinejoin="round"/>
                                        <path
                                            d="M15.9974 14.6667C17.8383 14.6667 19.3307 13.1743 19.3307 11.3333C19.3307 9.4924 17.8383 8 15.9974 8C14.1565 8 12.6641 9.4924 12.6641 11.3333C12.6641 13.1743 14.1565 14.6667 15.9974 14.6667Z"
                                            stroke="#FED780" strokeWidth="3" strokeLinejoin="round"/>
                                    </svg>
                                    CS 6: Tân Bình
                                </div>
                                <div className="address-item__content">Sân bóng H2 Tân Sơn, Hẻm 104 <br/> Tân Sơn, Phường 15, Quận Tân Bình.
                                    <br/>Tel: 0339.775.337 (Thầy Phúc); <br/>Tel: 0388.121.623 (Cô Tuyền)
                                </div>
                            </div>
                            <div className="address-item">
                                <div className="address-item__title">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6.5693 21.8379C4.15645 22.5618 2.66406 23.5618 2.66406 24.6663C2.66406 26.8754 8.6336 28.6663 15.9974 28.6663C23.3612 28.6663 29.3307 26.8754 29.3307 24.6663C29.3307 23.5618 27.8383 22.5618 25.4255 21.8379"
                                            stroke="#FED780" strokeWidth="3" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path
                                            d="M16.0026 23.3337C16.0026 23.3337 24.6693 17.6697 24.6693 11.1215C24.6693 6.45222 20.7891 2.66699 16.0026 2.66699C11.2161 2.66699 7.33594 6.45222 7.33594 11.1215C7.33594 17.6697 16.0026 23.3337 16.0026 23.3337Z"
                                            stroke="#FED780" strokeWidth="3" strokeLinejoin="round"/>
                                        <path
                                            d="M15.9974 14.6667C17.8383 14.6667 19.3307 13.1743 19.3307 11.3333C19.3307 9.4924 17.8383 8 15.9974 8C14.1565 8 12.6641 9.4924 12.6641 11.3333C12.6641 13.1743 14.1565 14.6667 15.9974 14.6667Z"
                                            stroke="#FED780" strokeWidth="3" strokeLinejoin="round"/>
                                    </svg>
                                    CS 7: Bình Chánh 
                                </div>
                                <div className="address-item__content"> Sân Đại Long 1, Hẻm C19/19a <br/> KDC Trung Sơn, Bình Chánh
                                    <br/>Tel: 0339.775.337 (Thầy Phúc); <br/>Tel: 0388.121.623 (Cô Tuyền)
                                </div>
                            </div>
                            <div className="address-item">
                                <div className="address-item__title">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6.5693 21.8379C4.15645 22.5618 2.66406 23.5618 2.66406 24.6663C2.66406 26.8754 8.6336 28.6663 15.9974 28.6663C23.3612 28.6663 29.3307 26.8754 29.3307 24.6663C29.3307 23.5618 27.8383 22.5618 25.4255 21.8379"
                                            stroke="#FED780" strokeWidth="3" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path
                                            d="M16.0026 23.3337C16.0026 23.3337 24.6693 17.6697 24.6693 11.1215C24.6693 6.45222 20.7891 2.66699 16.0026 2.66699C11.2161 2.66699 7.33594 6.45222 7.33594 11.1215C7.33594 17.6697 16.0026 23.3337 16.0026 23.3337Z"
                                            stroke="#FED780" strokeWidth="3" strokeLinejoin="round"/>
                                        <path
                                            d="M15.9974 14.6667C17.8383 14.6667 19.3307 13.1743 19.3307 11.3333C19.3307 9.4924 17.8383 8 15.9974 8C14.1565 8 12.6641 9.4924 12.6641 11.3333C12.6641 13.1743 14.1565 14.6667 15.9974 14.6667Z"
                                            stroke="#FED780" strokeWidth="3" strokeLinejoin="round"/>
                                    </svg>
                                    CS 8: Bình Chánh
                                </div>
                                <div className="address-item__content">Sân Đại Long Phạm Hùng, Hẻm C1 Phạm Hùng, Phường 5, <br/> Huyện Bình Chánh
                                    <br/>Tel: 0339.775.337 (Thầy Phúc); <br/>Tel: 0388.121.623 (Cô Tuyền)
                                </div>
                            </div>
                            <div className="address-item">
                                <div className="address-item__title">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6.5693 21.8379C4.15645 22.5618 2.66406 23.5618 2.66406 24.6663C2.66406 26.8754 8.6336 28.6663 15.9974 28.6663C23.3612 28.6663 29.3307 26.8754 29.3307 24.6663C29.3307 23.5618 27.8383 22.5618 25.4255 21.8379"
                                            stroke="#FED780" strokeWidth="3" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path
                                            d="M16.0026 23.3337C16.0026 23.3337 24.6693 17.6697 24.6693 11.1215C24.6693 6.45222 20.7891 2.66699 16.0026 2.66699C11.2161 2.66699 7.33594 6.45222 7.33594 11.1215C7.33594 17.6697 16.0026 23.3337 16.0026 23.3337Z"
                                            stroke="#FED780" strokeWidth="3" strokeLinejoin="round"/>
                                        <path
                                            d="M15.9974 14.6667C17.8383 14.6667 19.3307 13.1743 19.3307 11.3333C19.3307 9.4924 17.8383 8 15.9974 8C14.1565 8 12.6641 9.4924 12.6641 11.3333C12.6641 13.1743 14.1565 14.6667 15.9974 14.6667Z"
                                            stroke="#FED780" strokeWidth="3" strokeLinejoin="round"/>
                                    </svg>
                                    CS 9: Quận 8
                                </div>
                                <div className="address-item__content">Sân bóng đá Mini Quận 8, Số 8 <br/> Đường Thanh Loan, Phường 5, Quận 8.
                                    <br/>Tel: 0339.775.337 (Thầy Phúc); <br/>Tel: 0388.121.623 (Cô Tuyền)
                                </div>
                            </div>
                            <div className="address-item">
                                <div className="address-item__title">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6.5693 21.8379C4.15645 22.5618 2.66406 23.5618 2.66406 24.6663C2.66406 26.8754 8.6336 28.6663 15.9974 28.6663C23.3612 28.6663 29.3307 26.8754 29.3307 24.6663C29.3307 23.5618 27.8383 22.5618 25.4255 21.8379"
                                            stroke="#FED780" strokeWidth="3" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path
                                            d="M16.0026 23.3337C16.0026 23.3337 24.6693 17.6697 24.6693 11.1215C24.6693 6.45222 20.7891 2.66699 16.0026 2.66699C11.2161 2.66699 7.33594 6.45222 7.33594 11.1215C7.33594 17.6697 16.0026 23.3337 16.0026 23.3337Z"
                                            stroke="#FED780" strokeWidth="3" strokeLinejoin="round"/>
                                        <path
                                            d="M15.9974 14.6667C17.8383 14.6667 19.3307 13.1743 19.3307 11.3333C19.3307 9.4924 17.8383 8 15.9974 8C14.1565 8 12.6641 9.4924 12.6641 11.3333C12.6641 13.1743 14.1565 14.6667 15.9974 14.6667Z"
                                            stroke="#FED780" strokeWidth="3" strokeLinejoin="round"/>
                                    </svg>
                                    CS 10: Tân Phú 
                                </div>
                                <div className="address-item__content">Sân Celadon City, Đường N1, <br/> Phường Sơn Kỳ, Quận Tân Phú.
                                    <br/>Tel: 0339.775.337 (Thầy Phúc); <br/>Tel: 0388.121.623 (Cô Tuyền)
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bot">
                        <div className="footer-bot__cpr">Copyright Đăng Phúc</div>
                        <div className="footer-bot__nav flex">
                            {/* <div className="nav__item">Khoá học</div>
                            <div className="nav__item">Tin tức</div>
                            <div className="nav__item">Giới thiệu</div>
                            <div className="nav__item">Liên hệ</div> */}
                            <a href="/" className="nav__item">Trang chủ</a>
                            <a href="/khoa-hoc" className="nav__item">Khoá học</a>
                            <a href="/tin-tuc" className="nav__item">Tin tức</a>
                            <a href="/gioi-thieu" className="nav__item">Giới thiệu</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;