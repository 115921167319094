// src/pages/CourseComponent.js
import React, {useEffect, useState} from 'react';
import './CourseComponent.css';
import PropTypes from 'prop-types';
import { useDispatch} from 'react-redux';
import {toggleContactPopup} from '../../redux/actions/contactPopupActions';
CourseComponent.propTypes = {
    hasTitle: PropTypes.bool,
};

function CourseComponent({hasTitle = true}) {
    const [courses, setCourses] = useState([]);
    const dispatch = useDispatch();
    const handleToggle = () => {
        dispatch(toggleContactPopup());
    };

    useEffect(() => {
        async function fetchCourse() {
            try {
                // const response = await fetch('http://localhost/api/course', {
                const response = await fetch('https://admin.dangphucsport.vn/api/course', {
                    headers: {
                        'accept': 'application/json, text/plain, */*',
                    }
                });
                const data = await response.json();
                setCourses(data.data);
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        }

        fetchCourse();
    }, []);
    if (!courses?.length) {
        return <div className="loader-container">
            <div className="loader"></div>
        </div>; // Hiển thị nếu không có dữ liệu
    }
    return (
        <div className="block-course">
            {hasTitle && (
                <div className="course-title">
                    Các gói tập
                </div>
            )}
            <div className="course-content">
                {courses.map((item, index) => (
                    <div className="course-content-item">
                        <div className="top-icon">
                            <svg width="64" height="65" viewBox="0 0 64 65" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14.6641 28.25V48.3017C14.6641 49.1117 15.0299 49.8751 15.711 50.3137C17.9791 51.7744 23.8099 54.9167 31.9974 54.9167C40.1849 54.9167 46.0157 51.7744 48.2838 50.3137C48.9649 49.8751 49.3307 49.1117 49.3307 48.3017V28.25"
                                    stroke="#616161" strokeWidth="3" strokeLinejoin="round"/>
                                <path d="M57.3359 32.25V42.9167" stroke="#616161" strokeWidth="3" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                                <path
                                    d="M6.66406 22.9168L31.9974 9.5835L57.3307 22.9168L31.9974 36.2502L6.66406 22.9168Z"
                                    stroke="#616161" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <div className="course-content-item__name">{item.title}</div>
                        {JSON.parse(item?.content || '').des.map((itemDes, index) => (

                        <div className="course-content-item__info-item">
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12 24.25C18.6276 24.25 24 18.8776 24 12.25C24 5.6224 18.6276 0.25 12 0.25C5.3724 0.25 0 5.6224 0 12.25C0 18.8776 5.3724 24.25 12 24.25Z"
                                    fill="url(#paint0_linear_419_1855)"/>
                                <path d="M6.57031 13.0259L9.67353 16.1291L17.4316 8.37109"
                                      fill="url(#paint1_linear_419_1855)"/>
                                <path d="M6.57031 13.0259L9.67353 16.1291L17.4316 8.37109" stroke="white"
                                      strokeWidth="2"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                                <defs>
                                    <linearGradient id="paint0_linear_419_1855" x1="-6.79245" y1="-19.1346" x2="26.279"
                                                    y2="-16.2075" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#FEDB99"/>
                                        <stop offset="1" stopColor="#FEBB64"/>
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_419_1855" x1="3.49637" y1="2.10499" x2="18.3521"
                                                    y2="3.94579" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#FEDB99"/>
                                        <stop offset="1" stopColor="#FEBB64"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                            <div className="info-item__text">{itemDes}</div>
                        </div>
                        ))}

                        {/* <div className="course-content-item__price">{item.price}</div> */}
                        <div className="course-content-item__ul">Bao gồm</div>
                        {JSON.parse(item?.content || '').include.map((itemInclude, index) => (

                            <div className="course-content-item__info-item">
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12 24.25C18.6276 24.25 24 18.8776 24 12.25C24 5.6224 18.6276 0.25 12 0.25C5.3724 0.25 0 5.6224 0 12.25C0 18.8776 5.3724 24.25 12 24.25Z"
                                        fill="url(#paint0_linear_419_1855)"/>
                                    <path d="M6.57031 13.0259L9.67353 16.1291L17.4316 8.37109"
                                          fill="url(#paint1_linear_419_1855)"/>
                                    <path d="M6.57031 13.0259L9.67353 16.1291L17.4316 8.37109" stroke="white"
                                          strokeWidth="2"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                    <defs>
                                        <linearGradient id="paint0_linear_419_1855" x1="-6.79245" y1="-19.1346"
                                                        x2="26.279"
                                                        y2="-16.2075" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#FEDB99"/>
                                            <stop offset="1" stopColor="#FEBB64"/>
                                        </linearGradient>
                                        <linearGradient id="paint1_linear_419_1855" x1="3.49637" y1="2.10499"
                                                        x2="18.3521"
                                                        y2="3.94579" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#FEDB99"/>
                                            <stop offset="1" stopColor="#FEBB64"/>
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <div className="info-item__text">{itemInclude}</div>
                            </div>
                        ))}

                        <div className="course-content-item__ul">Ưu đãi trong gói</div>
                        {JSON.parse(item?.content || '').promotion.map((itemPromo, index) => (
                        <div className="course-content-item__info-item">
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12 24.25C18.6276 24.25 24 18.8776 24 12.25C24 5.6224 18.6276 0.25 12 0.25C5.3724 0.25 0 5.6224 0 12.25C0 18.8776 5.3724 24.25 12 24.25Z"
                                    fill="url(#paint0_linear_419_1855)"/>
                                <path d="M6.57031 13.0259L9.67353 16.1291L17.4316 8.37109"
                                      fill="url(#paint1_linear_419_1855)"/>
                                <path d="M6.57031 13.0259L9.67353 16.1291L17.4316 8.37109" stroke="white"
                                      strokeWidth="2"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                                <defs>
                                    <linearGradient id="paint0_linear_419_1855" x1="-6.79245" y1="-19.1346" x2="26.279"
                                                    y2="-16.2075" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#FEDB99"/>
                                        <stop offset="1" stopColor="#FEBB64"/>
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_419_1855" x1="3.49637" y1="2.10499" x2="18.3521"
                                                    y2="3.94579" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#FEDB99"/>
                                        <stop offset="1" stopColor="#FEBB64"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                            <div className="info-item__text" dangerouslySetInnerHTML={{ __html: itemPromo }}>

                            </div>
                        </div>
                        ))}

                        {/*<a href={item.url} className="course-content-item__btn">Đăng ký ngay</a>*/}
                        <div onClick={handleToggle} className="course-content-item__btn">Đăng ký ngay</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CourseComponent;