import React, { useEffect, useState } from 'react';
import './AllNewsComponent.css';
import PropTypes from 'prop-types';

// Define propTypes
AllNewsComponent.propTypes = {
    hasTitle: PropTypes.bool,
};

function AllNewsComponent({ hasTitle = true }) {
    const [news, setNews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    useEffect(() => {
        async function fetchNews() {
            try {
                const response = await fetch('https://admin.dangphucsport.vn/api/news', {
                    headers: {
                        accept: 'application/json, text/plain, */*',
                    },
                });
                const data = await response.json();
                setNews(data.data || []);
            } catch (error) {
                console.error('Error fetching news:', error);
            }
        }

        fetchNews();
    }, []);

    if (!news?.length) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    }
    const totalPages = Math.ceil((news.length - 1) / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedNews = news.slice(1).slice(startIndex, startIndex + itemsPerPage);

    // Generate page numbers array
    const getPageNumbers = () => {
        const pageNumbers = [];
        const maxVisiblePages = 5;

        if (totalPages <= maxVisiblePages) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            if (currentPage <= 3) {
                for (let i = 1; i <= 3; i++) pageNumbers.push(i);
                pageNumbers.push('...');
                pageNumbers.push(totalPages);
            } else if (currentPage >= totalPages - 2) {
                pageNumbers.push(1);
                pageNumbers.push('...');
                for (let i = totalPages - 2; i <= totalPages; i++) pageNumbers.push(i);
            } else {
                pageNumbers.push(1);
                pageNumbers.push('...');
                pageNumbers.push(currentPage - 1);
                pageNumbers.push(currentPage);
                pageNumbers.push(currentPage + 1);
                pageNumbers.push('...');
                pageNumbers.push(totalPages);
            }
        }
        return pageNumbers;
    };
    return (
        <div className="all-block-news">
            {hasTitle && (
                <div className="all-news-title">
                    <div className="all-news-title__title">Tin tức</div>
                    <a href="/tin-tuc" className="news-title__btn btn-dp">
                        Xem tất cả
                        <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.04751 4.60205L15.9453 12.4999L8.04751 20.3977"
                                stroke="#5C5C5C"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </a>
                </div>
            )}
            <div className="all-news-content">
                {/* Main News */}
                <a
                    href={news[0]?.url || '#'}
                    className="all-news-content__main"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className="all-sub-main-img">
                        <img
                            src={`https://admin.dangphucsport.vn/${news[0]?.image_path}`}
                            alt={news[0]?.title}
                        />
                    </div>
                    <div className="all-main-content">
                        <div className='custom-flex custom'>
                            <div className="all-content-title">{news[0]?.title}</div>
                            <div className="divider-line mt-3"></div>
                            <div className="all-content-text">
                                <div dangerouslySetInnerHTML={{ __html: news[0]?.content }}></div>
                            </div>
                        </div>
                        <div className="all-content-more-info">
                            <div className="content-more-info__view">{news[0]?.view || 50} lượt xem</div>
                            <div className="content-more-info__date">1 ngày trước</div>
                        </div>
                    </div>
                </a>
                {/* Sub News - Dynamic with 3 items per row */}
                <div className="all-news-content__sub">
                    {Array.from({ length: Math.ceil(paginatedNews.slice(1).length / 3) }, (_, rowIndex) => (
                        <div className="all-news-row" key={rowIndex}>
                            {paginatedNews
                                .slice(1 + rowIndex * 3, 1 + (rowIndex + 1) * 3)
                                .map((item, index) => (
                                    <a
                                        href={item?.url || '#'}
                                        className="all-sub-item"
                                        key={index}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <div className="all-sub-img">
                                            <img
                                                src={`https://admin.dangphucsport.vn/${item.image_path}`}
                                                alt={item.title}
                                            />
                                        </div>
                                        <div className="all-sub-main-content">
                                            <div className="all-content-title">{item.title || 'Tiêu đề mặc định'}</div>
                                            <div className="divider-line mt-3"></div>
                                            <div className="all-content-text custom">
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: item.content }}
                                                ></div>
                                            </div>
                                            <div className="all-content-more-info">
                                                <div className="content-more-info__view">{item.view || 50} lượt xem</div>
                                                <div className="content-more-info__date">1 ngày trước</div>
                                            </div>
                                        </div>
                                    </a>
                                ))}
                        </div>
                    ))}
                    <div className="pagination">
                        <button
                            className="pagination-arrow"
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                        >
                            ‹
                        </button>

                        {getPageNumbers().map((number, index) => (
                            <button
                                key={index}
                                className={`pagination-number ${number === currentPage ? 'active' : ''} ${number === '...' ? 'dots' : ''}`}
                                onClick={() => number !== '...' && setCurrentPage(number)}
                                disabled={number === '...'}
                            >
                                {number}
                            </button>
                        ))}

                        <button
                            className="pagination-arrow"
                            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                            disabled={currentPage === totalPages}
                        >
                            ›
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AllNewsComponent;
