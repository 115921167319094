// src/pages/AllImagesPage.js
import React, { useEffect, useState } from 'react';
import './AllImagesPage.css';
import 'spotlight.js/dist/css/spotlight.min.css';
import PropTypes from 'prop-types';
import Spotlight from 'spotlight.js';

AllImagesPage.propTypes = {
    hasTitle: PropTypes.bool,
};

function AllImagesPage({ hasTitle = true }) {
    const [images, setImages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 9;

    useEffect(() => {
        async function fetchImages() {
            try {
                const response = await fetch('https://admin.dangphucsport.vn/api/imageLP', {
                    headers: {
                        accept: 'application/json, text/plain, */*',
                    },
                });
                const data = await response.json();
                setImages(data.data || []);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        }
        fetchImages();
    }, []);

    useEffect(() => {
        const initializeSpotlight = () => {
            if (window.Spotlight) {
                window.Spotlight.init();
            }
        };

        if (document.readyState === 'complete') {
            initializeSpotlight();
        } else {
            window.addEventListener('load', initializeSpotlight);
        }
    }, []);

    if (!images.length) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    }

    const totalPages = Math.ceil(images.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedImages = images.slice(startIndex, startIndex + itemsPerPage);

    const getPageNumbers = () => {
        const pageNumbers = [];
        const maxVisiblePages = 5;

        if (totalPages <= maxVisiblePages) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            if (currentPage <= 3) {
                for (let i = 1; i <= 3; i++) pageNumbers.push(i);
                pageNumbers.push('...');
                pageNumbers.push(totalPages);
            } else if (currentPage >= totalPages - 2) {
                pageNumbers.push(1);
                pageNumbers.push('...');
                for (let i = totalPages - 2; i <= totalPages; i++) pageNumbers.push(i);
            } else {
                pageNumbers.push(1);
                pageNumbers.push('...');
                pageNumbers.push(currentPage - 1);
                pageNumbers.push(currentPage);
                pageNumbers.push(currentPage + 1);
                pageNumbers.push('...');
                pageNumbers.push(totalPages);
            }
        }
        return pageNumbers;
    };

    return (
        <div className="dp-container relative-content">
        <div className="all-block-news">
            {hasTitle && (
                <div className="all-news-title">
                    <div className="all-news-title__title">Hình ảnh</div>
                    <a href="/tin-tuc" className="news-title__btn btn-dp">
                        Xem tất cả
                        <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.04751 4.60205L15.9453 12.4999L8.04751 20.3977"
                                stroke="#5C5C5C"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </a>
                </div>
            )}
                <div className="all-news-content">
                    <div className="all-news-content__sub spotlight-group">
                        <div className="all-news-row">
                            {paginatedImages.map((item, index) => (
                                <a
                                    href={`https://admin.dangphucsport.vn/${item.image_path}`}
                                    className="all-sub-item spotlight"
                                    key={index}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <div className="all-sub-img">
                                        <img
                                            src={`https://admin.dangphucsport.vn/${item.image_path}`}
                                            alt={`img-${index}`}
                                        />
                                    </div>
                                </a>
                            ))}
                        </div>

                        <div className="pagination">
                            <button
                                className="pagination-arrow"
                                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                disabled={currentPage === 1}
                            >
                                ‹
                            </button>

                            {getPageNumbers().map((number, index) => (
                                <button
                                    key={index}
                                    className={`pagination-number ${number === currentPage ? 'active' : ''} ${number === '...' ? 'dots' : ''}`}
                                    onClick={() => number !== '...' && setCurrentPage(number)}
                                    disabled={number === '...'}
                                >
                                    {number}
                                </button>
                            ))}

                            <button
                                className="pagination-arrow"
                                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                disabled={currentPage === totalPages}
                            >
                                ›
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default AllImagesPage;
