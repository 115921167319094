// src/pages/News.js
import React, { useEffect, useState } from 'react';
import "./SingleNewPage.css"
import trongDongBall from "../../assets/images/swiper-home-bg.png";
import { useParams } from 'react-router-dom';

function SingleNewPage() {
    const [news, setNews] = useState(null); // Khởi tạo với null
    const [allNews, setAllNews] = useState([]); // Khởi tạo với null
    const { newId } = useParams();
    useEffect(() => {
        if (!newId) return;
        async function fetchImages() {
            try {
                // const response = await fetch('http://localhost/api/news', {
                const response = await fetch('https://admin.dangphucsport.vn/api/news', {
                    headers: {
                        'accept': 'application/json, text/plain, */*',
                    }
                });
                const data = await response.json();
                if (data.data?.length) {
                    // Tìm kiếm dữ liệu theo newId
                    setAllNews(data.data); // Cập nhật state news
                    const foundNews = data.data.find((item) => item.url === newId);
                    console.log('News found:', foundNews);
                    setNews(foundNews); // Cập nhật state news
                }
            } catch (error) {
                console.error('Error fetching news:', error);
            }
        }

        fetchImages();
    }, [newId]);
    if (!Object.keys(news || {}).length || !allNews?.length) {
        return <div className="loader-container">
            <div className="loader"></div>
        </div>; // Hiển thị nếu không có dữ liệu
    }
    return (
        <div className="news-container">
            <div className="news-bg-top">
                <img src={trongDongBall} alt="Logo" />
            </div>
            <div className="dp-container relative-content">
                <div className="item-swiper__title">
                    Từ sân trường đến sân cỏ chuyên nghiệp <br />Cùng <span>Đăng phúc</span>
                </div>
                <div className="item-swiper__cta mb-10">
                    <a href="/" className="btn-dp">
                        Khám phá ngay
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.04751 4.60205L15.9453 12.4999L8.04751 20.3977" stroke="#5C5C5C"
                                strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </a>
                </div>
            </div>
            <div className="dp-container relative-content">
                <div className="single-new-container">
                    <div className="single-new__content">
                        <div className="content__title">
                            {news?.title || "No content found"}
                        </div>
                        <div className="content__text">
                            <div dangerouslySetInnerHTML={{ __html: news.content }}>

                            </div>
                        </div>
                    </div>
                    <div className="block-sforum">
                        <div className="sforum__title">
                            <span className="icon">📰</span> {/* Tạm thay thế icon từ Vue */}
                            Tất cả tin tức
                        </div>
                        <div className="sforum__content">
                            {allNews.slice(0, 5).map((item, key) => (
                                <a
                                    key={key}
                                    href={(item?.url || '#')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="sforum__content-item button__link is-flex"
                                >
                                    <img className='content-item__img' src={'https://admin.dangphucsport.vn/' + item.image_path} alt={item.title} />
                                    <div className="content-item__text" dangerouslySetInnerHTML={{ __html: item.title }} />
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SingleNewPage;