// src/pages/NewsComponent.js
import React, { useEffect, useState } from 'react';
import './NewsComponent.css';
import PropTypes from 'prop-types';

// Khai báo propTypes
NewsComponent.propTypes = {
    hasTitle: PropTypes.bool,
};

function NewsComponent({ hasTitle = true }) {
    const [news, setNews] = useState([]);

    useEffect(() => {
        async function fetchImages() {
            try {
                const response = await fetch('https://admin.dangphucsport.vn/api/news', {
                    headers: {
                        accept: 'application/json, text/plain, */*',
                    },
                });
                const data = await response.json();
                setNews(data.data);
            } catch (error) {
                console.error('Error fetching news:', error);
            }
        }

        fetchImages();
    }, []);

    if (!news?.length) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        ); // Hiển thị nếu không có dữ liệu
    }

    return (
        <div className="block-news">
            {hasTitle && (
                <div className="news-title">
                    <div className="news-title__title">Tin tức</div>
                    <a href="/tin-tuc" className="news-title__btn btn-dp" target="_blank" rel="noopener noreferrer">
                        Xem tất cả
                        <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.04751 4.60205L15.9453 12.4999L8.04751 20.3977"
                                stroke="#5C5C5C"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </a>
                </div>
            )}
            <div className="news-content">
                {/* Main News */}
                <a
                    href={news[0]?.url || '#'}
                    className="news-content__main"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className="main-img">
                        <img src={'https://admin.dangphucsport.vn/' + news[0]?.image_path} alt={news[0]?.title} />
                    </div>
                    <div className="main-content flex flex-column">
                        <div className="content-title">{news[0]?.title}</div>
                        <div className="divider-line mt-3"></div>
                        <div className="content-text">
                            <div className ='custom' dangerouslySetInnerHTML={{ __html: news[0]?.content }}></div>
                        </div>
                        <div className="content-more-info">
                            <div className="content-more-info__view">{news[0]?.view || 50} lượt xem</div>
                            <div className="content-more-info__date">1 ngày trước</div>
                        </div>
                    </div>
                </a>
                {/* Sub News */}
                <div className="news-content__sub">
                    {news.slice(1, 4).map((item, index) => (
                        <a
                            href={item?.url || '#'}
                            className="sub-item"
                            key={index}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className="sub-img">
                                <img src={'https://admin.dangphucsport.vn/' + item?.image_path} alt={item?.title} />
                            </div>
                            <div className="main-content flex flex-column" style={{ width: 'unset' }}>
                                <div className="content-title">{item?.title || 'Tiêu đề mặc định'}</div>
                                <div className="divider-line mt-3"></div>
                                <div className="content-text mb-2">
                                    <div className ='custom' dangerouslySetInnerHTML={{ __html: item?.content }}></div>
                                </div>
                                <div className="content-more-info mt-2">
                                    <div className="content-more-info__view">{item?.view || 50} lượt xem</div>
                                    <div className="content-more-info__date">1 ngày trước</div>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default NewsComponent;