// src/pages/News.js
import React from 'react';
import "./News.css"
import trongDongBall from "../../assets/images/swiper-home-bg.png";
import NewsComponent from "../../components/NewsComponent/NewsComponent";
import AllNewsComponent from "../../components/AllNewsComponent/AllNewsComponent";

function News() {
    return (
        <div className="news-container">
            <div className="news-bg-top">
                <img src={trongDongBall} alt="Logo"/>
            </div>
            <div className="dp-container relative-content">
                <div className="item-swiper__title">
                    Từ sân trường đến sân cỏ chuyên nghiệp <br/>Cùng <span>Đăng phúc</span>
                </div>
                <div className="item-swiper__cta mb-10">
                    <a href="/" className="btn-dp">
                        Khám phá ngay
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.04751 4.60205L15.9453 12.4999L8.04751 20.3977" stroke="#5C5C5C"
                                  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </a>
                </div>
                <AllNewsComponent/>
            </div>
        </div>
    );
}

export default News;