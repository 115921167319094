// src/pages/About.js
import React, { useEffect, useState } from 'react';
import './About.css'; // Ensure CSS is linked
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Pagination } from 'swiper/modules'; // Import Swiper Pagination module
import 'swiper/css'; // Import Swiper styles
import 'swiper/css/pagination';
import DOMPurify from 'dompurify';
import Image3 from '../../assets/images/page_3_image.png';
import Image4 from '../../assets/images/page_4_image.png';
import Image7 from '../../assets/images/page_7_image.png';
import Image11 from '../../assets/images/page_11_image.png';
import Image12 from '../../assets/images/page_12_image.png';
import Image8 from '../../assets/images/page_8_image.png';
import Image9 from '../../assets/images/page_9_image.png';

function decodeHtml(html) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = html;
    return textArea.value;
}

function About() {
    const [images, setImages] = useState([]);
    const [textSliders, setTextSliders] = useState([]);

    useEffect(() => {
        async function fetchImages() {
            try {
                const response = await fetch('https://admin.dangphucsport.vn/api/images', {
                    headers: {
                        'accept': 'application/json, text/plain, */*',
                    },
                });
                const data = await response.json();
                setImages(data.data);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        }

        async function fetchTextSliders() {
            try {
                const response = await fetch('https://admin.dangphucsport.vn/api/textsliders', {
                    headers: {
                        'accept': 'application/json, text/plain, */*',
                    },
                });
                const result = await response.json();

                if (result && Array.isArray(result.data)) {
                    const decodedSliders = result.data.map((slider) => ({
                        ...slider,
                        title: decodeHtml(slider.title),
                        textbutton: decodeHtml(slider.textbutton),
                    }));
                    setTextSliders(decodedSliders);
                } else {
                    console.error('Invalid data format from API:', result);
                    setTextSliders([]);
                }
            } catch (error) {
                console.error('Error fetching text sliders:', error);
                setTextSliders([]);
            }
        }

        fetchImages();
        fetchTextSliders();
    }, []);

    return (
        <div className="home-container">
            <div className="bg-trong-dong-color">
                <div className="bg-trong-dong">
                    <div className="dp-container">
                        <Swiper
                            className="swiper-home"
                            modules={[Pagination]}
                            pagination={{
                                clickable: true,
                            }}
                        >
                            {textSliders.length > 0 ? (
                                textSliders.slice(0, 5).map((slider, index) => (
                                    <SwiperSlide key={`slider-${index}`}>
                                        <div className="item-swiper">
                                            {/* <div
                                                className="item-swiper__title"
                                                dangerouslySetInnerHTML={{
                                                    __html: DOMPurify.sanitize(slider.title),
                                                }}
                                            ></div>
                                            <div className="item-swiper__cta">
                                                <a href={slider.path} className="btn-dp">
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: DOMPurify.sanitize(slider.textbutton),
                                                        }}
                                                    ></span>
                                                    <svg
                                                        width="24"
                                                        height="25"
                                                        viewBox="0 0 24 25"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M8.04751 4.60205L15.9453 12.4999L8.04751 20.3977"
                                                            stroke="#5C5C5C"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </a>
                                            </div> */}
                                        </div>
                                    </SwiperSlide>
                                ))
                            ) : (
                                <p></p>
                            )}
                        </Swiper>
                        <div className="info-col">
                            <div className="info-col__item">
                                <div className="item-num">1000+</div>
                                <div className="item-text">Học viên</div>
                            </div>
                            <div className="info-col__item">
                                <div className="item-num">10</div>
                                <div className="item-text">Cơ sở</div>
                            </div>
                            <div className="info-col__item">
                                <div className="item-num">100+</div>
                                <div className="item-text">Giáo viên</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dp-container-img">
                <div className="block-img">
                    <img src={Image3} alt="Image 1" />
                    <img src={Image4} alt="Image2" />
                    <img src={Image7} alt="Image3" />
                    <img src={Image8} alt="Image4" />
                    <img src={Image9} alt="Image5" />
                    <img src={Image11} alt="Image6" />
                    <img src={Image12} alt="Image7" />
                </div>
            </div>
        </div>
    );
}

export default About;