// Khởi tạo state ban đầu
const initialState = {
    isOpenContactPopup: false,
};

// Định nghĩa actions type
const TOGGLE_CONTACT_POPUP = 'TOGGLE_CONTACT_POPUP';

// Reducer để xử lý state
export const contactPopupReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_CONTACT_POPUP:
            return {
                ...state,
                isOpenContactPopup: !state.isOpenContactPopup, // Đảo trạng thái
            };
        default:
            return state;
    }
};
