import { createStore, combineReducers } from 'redux';
import { contactPopupReducer } from './reducers/contactPopupReducer';

// Kết hợp các reducer (nếu có nhiều)
const rootReducer = combineReducers({
    contactPopup: contactPopupReducer,
});

// Tạo store
const store = createStore(rootReducer);

export default store;
