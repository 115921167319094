// src/pages/Consulting.js
import React from 'react';

function Consulting() {
    return (
        <div className="pb-10 pt-10">
        </div>
    );
}

export default Consulting;