// src/pages/VideosComponent.js
import React, { useRef, useEffect } from 'react';
import './VideoComponent.css';

import video1 from '../../assets/videos/reel1.mp4';
import video2 from '../../assets/videos/reel2.mp4';
import video3 from '../../assets/videos/reel3.mp4';
import video4 from '../../assets/videos/reel4.mp4';

function VideosComponent() {
    const videos = [video1, video2, video3, video4];
    const videoRefs = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const video = entry.target;
                    if (entry.isIntersecting) {
                        video.load();
                    }
                });
            },
            {
                threshold: 0.25,
            }
        );

        videoRefs.current.forEach((video) => {
            if (video) {
                observer.observe(video);
            }
        });

        return () => {
            videoRefs.current.forEach((video) => {
                if (video) {
                    observer.unobserve(video);
                }
            });
        };
    }, []);

    return (
        <div className="block-videos">
            <div className="videos-title">
                <div className="videos-title__title">Video</div>
            </div>
            <div className="videos-content">
                {videos.map((videoSrc, index) => (
                    <div className="video-item" key={index}>
                        <video
                            ref={(el) => (videoRefs.current[index] = el)}
                            controls
                            preload="none" 
                            width="100%"
                            height="auto"
                        >
                            <source src={videoSrc} type="video/mp4" />
                        </video>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default VideosComponent;
