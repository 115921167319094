// src/components/JerseyComponent.js
import React from 'react';
import './JerseyComponent.css';
import jersey1 from '../../assets/images/jersey1.jpeg';
import jersey2 from '../../assets/images/jersey2.jpeg';
import jersey3 from '../../assets/images/jersey3.jpeg';
import jersey4 from '../../assets/images/jersey4.jpeg';
import jersey5 from '../../assets/images/jersey5.jpeg';
import 'spotlight.js/dist/css/spotlight.min.css';
import Spotlight from 'spotlight.js';

function JerseyComponent() {
    const jerseys = [jersey1, jersey2, jersey3, jersey4, jersey5];

    React.useEffect(() => {
        if (window.Spotlight) {
            window.Spotlight.init();
        } else {
            window.addEventListener('load', () => {
                if (window.Spotlight) {
                    window.Spotlight.init();
                }
            });
        }
    }, []);

    return (
        <div className="block-jersey">
            <div className="jersey-title">
                <div className="jersey-title__title">Đồng phục học viên</div>
                {/* <a href="/dong-phuc" className="jersey-title__btn btn-dp">
                    Xem tất cả
                    <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8.04751 4.60205L15.9453 12.4999L8.04751 20.3977"
                            stroke="#5C5C5C"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </a> */}
            </div>
            <div className="desktop">
                <div className="jersey-content spotlight-group">
                    <div className="flex gap-4">
                        <a href={jerseys[3]} className="spotlight item-3-col" style={{ height: '364px' }}>
                            <img alt="jersey" src={jerseys[3]} />
                        </a>
                        <a href={jerseys[4]} className="spotlight item-3-col" style={{ height: '364px' }}>
                            <img alt="jersey" src={jerseys[4]} />
                        </a>
                        <a href={jerseys[2]} className="spotlight item-3-col" style={{ height: '364px' }}>
                            <img alt="jersey" src={jerseys[2]} />
                        </a>
                    </div>
                    <div className="flex gap-4">
                        <a href={jerseys[0]} className="spotlight item-4-col" style={{ height: '528px' }}>
                            <img alt="jersey" src={jerseys[0]} />
                        </a>
                        <a href={jerseys[1]} className="spotlight item-5-col" style={{ height: '528px' }}>
                            <img alt="jersey" src={jerseys[1]} />
                        </a>
                    </div>
                </div>
            </div>
            {window.innerWidth <= 768 && (
                <div className="mobile">
                    <div className="jersey-content spotlight-group">
                        {jerseys.map((img, idx) => (
                            <a
                                href={img}
                                className="spotlight item-full"
                                key={idx}
                                style={{ height: '528px' }}
                            >
                                <img alt={`jersey-${idx}`} src={img} />
                            </a>
                        ))}
                    </div>
                </div>
            )}

        </div>
    );
}

export default JerseyComponent;
